export function pingChat() {
  const tick = 500
  const maxCount = process.env.CHAT_INIT_TIMEOUT * 1000 * 1.15
  let count = 0
  function ping() {
    const el = document.getElementById('chat-widget-container')
    if(!el && count < maxCount) {
      count = count + tick
      return setTimeout(ping, tick)
    }
    setChatOffset()
  }
  return ping()
}

export function setChatOffset() {
  const el = document.getElementById('chat-widget-container')
  if(el) {
    const bannerHeight = (document.getElementById('cookie-banner')?.offsetHeight || 0) - 20
    el.style.marginBottom = `${bannerHeight}px`
  }
}

export function resetChatOffset() {
  const el = document.getElementById('chat-widget-container')
  if(el && el.style.marginBottom) {
    el.style.marginBottom = '-20px'
  }
}
