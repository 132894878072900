import { useEffect } from 'react'
import debounce from 'lodash/debounce'

import { useTranslations } from 'common/language'
import Container from 'common/widgets/Container'
import useLocalStorage from 'hooks/useLocalStorage'
import { pingChat, setChatOffset, resetChatOffset } from './utils'
import classNames from './styles.module.scss'


const debouncedOffsetTrigger = debounce(setChatOffset, 100)

export default function CookieBannerView() {
  const { languageCode, gettext } = useTranslations()
  const [ isCookieAccepted, setCookieAccepted ] = useLocalStorage('isCookieAccepted', false)

  useEffect(() => {
    if(!isCookieAccepted) {
      pingChat()
      window.addEventListener('resize', debouncedOffsetTrigger)
    } else {
      window.removeEventListener('resize', debouncedOffsetTrigger)
      resetChatOffset()
    }
  }, [isCookieAccepted])

  if(typeof window === 'undefined' || isCookieAccepted) {
    return null
  }

  const linkUrl = languageCode === 'fr' ? 'https://www.boalingua.ch/fr/protection-des-donnees' : 'https://www.boalingua.ch/de/datenschutz'

  return (
    <div className={classNames.wrapper} id="cookie-banner">
      <Container size="xl">
        <div className={classNames.row}>
          <div>
            {gettext('In order to optimally design our website for you and to be able to continuously improve it, we use cookies. By continuing to use our websites and products, you agree to the use of cookies.')} <a href={linkUrl} target="_blank" rel="noreferrer">{gettext('Learn more')}</a>
          </div>
          <button onClick={() => setCookieAccepted(true)}>
            {gettext('Understand')}
          </button>
        </div>
      </Container>
    </div>
  )
}
